import { useEffect, useState } from 'react';
import './App.css';
import { Box, Button, Container, createTheme, Grid, Icon, makeStyles,Paper, Tab, Tabs, ThemeProvider, Typography } from '@material-ui/core';
import HideAppBar from './components/scrollingAppBar';
import Web3Modal from "web3modal";
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { ByoaApp } from './types/byoaApp';
import AppCard from './components/appCard';
import { InstalledApp } from './types/installedApp';
import InstalledAppCard from './components/installedAppCard';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Import the abi
var byoaAbi = require('./utils/abi/Byoa.json');

const byoaContractAddress = `0x8f15c4ea6ce3fbfc5f7402c5766fc94202704161`;
const providerNetwork = `https://eth-mainnet.alchemyapi.io/v2/N9hhfuCL7V9y5dXCD5AOddGs-zVIyYc4`;



const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    padding: 20,
    minHeight: '100vh'
  },
  apps: {
    marginTop: 40
  },
  myApps: {
      marginTop: 40
  },
  appCard: {
    background: 'rgba(255,255,255,0.5)',
    marginTop: 20,
    marginBottom: 20,
    padding: 20
  },

  infoContainer: {
    marginTop: 20,
    '& button,a': {
      marginRight: 25
    }
  },
  tabBar: {
      marginTop: 20,
      marginBottom: 20,
  },
  tabBarPaper: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    paddingLeft: 10
  },
  tabBarTabs: {
      color: 'white',
      fontWeight: 'bold'
  }
 
});

const theme = createTheme({
  typography: {
    fontFamily: [
      'Chivo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 900
    }
  },
});

const providerOptions = {  
  walletconnect: {
    display: {
      name: "Mobile"
    },
    package: WalletConnectProvider,
    options: {
      infuraId: "7ef60ed2d6064931ba91c9572eeb275c" // required

    }
  }
};

const web3Modal = new Web3Modal({
  network: providerNetwork, // optional
  cacheProvider: true, // optional
  disableInjectedProvider: false,
  providerOptions // required
});

function App() {
  const classes = useStyles();
  const [provider, setProvider] = useState<any>(null);
  const [web3, setWeb3] = useState<any>(null);
  const [accountAddress, setAccountAddress] = useState<String | null>(null);
  const [apps, setApps] = useState<ByoaApp[]>([]);
  const [viewTab, setViewTab] = useState<Number>(0);
  const [installedApps, setInstalledApps] = useState<InstalledApp[]>([]);

  useEffect( () => {
    fetchAvailableApps();
  }, []);

  const connectWallet = async () => {
    try {
      let p = provider;
      if (p === null) {
        p = await web3Modal.connect();
        if(p === null) {
          throw new Error('Unable to connect provider to modal');
        }
        p.on('accountsChanged', (e : any) => {
            console.log(e);
          disconnectWallet();
        });
            p.on("chainChanged", (chainId: number) => {
                console.log("chain " + chainId);
            });
        setProvider(p);
      }

      let w3 = web3;
      if (w3 === null) {
        w3 = new Web3(p);
        if (w3 === null) {
          throw new Error('Unable to connect web3');
        }
        setWeb3(w3);
      }

      const accounts = await p.request({method: 'eth_accounts'});
      if (accounts.length > 0) {
        setAccountAddress(accounts[0]);
        setTimeout( async () => {
            refreshMyApps(accounts[0]);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      alert('Unable to connect wallet. Please try again.');
    }
  };

  const disconnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    setProvider(null);
    setAccountAddress(null);
    setInstalledApps([]);
  };

  const handleConnection = async () => {
    if (accountAddress === null) connectWallet();
    else disconnectWallet();
  };

  const fetchAvailableApps = async () => {
    let w3 = new Web3(providerNetwork);
    try {
      let contract = new w3.eth.Contract(byoaAbi['abi'], byoaContractAddress);
      let appIds = await contract.methods.getAppIds().call();
      console.log(appIds);

      let allApps = [];
      for(let i = 0; i < appIds.length; i ++) {
        let id : Number = parseInt(appIds[i]);
        // Get the details
        let appDetails = await contract.methods.getAppDetailsById(id).call();
        console.log(appDetails);
        
        let app : ByoaApp = {
          id: id,
          name: appDetails[0],
          description: appDetails[1],
          tokenURI: appDetails[2],
          owner: appDetails[3],
          price: parseInt(appDetails[4]),
          address: byoaContractAddress,
          version: 'beta v0.1'
        };

        allApps.push(app);
      }

      setApps(allApps)
      
    } catch( error ) {
      console.log(`Error fetching apps: ${error}`) ;
    }
  };

  const refreshMyApps = async (addressHelper : String | undefined | null) => {
    let w3 = new Web3(providerNetwork);
    try {
        let contract = new w3.eth.Contract(byoaAbi['abi'], byoaContractAddress);
      
        let myTokenIds = await contract.methods.walletOfOwner(accountAddress ? accountAddress : addressHelper).call();
        console.log(myTokenIds);

        let appLUT : any = {};
        apps.forEach( (app) => {
            appLUT[`${app.id}`] = app;
        });

        let allInstalls : InstalledApp[] = [];
        for (var i = 0; i < myTokenIds.length; i ++) {
            let tid = parseInt(myTokenIds[i]);
            let appIdForToken = await contract.methods.getAppIdByTokenId(tid).call();

            let ia : InstalledApp = {
                id: tid,
                tokenURI: "",
                app: appLUT[appIdForToken]
            }

            allInstalls.push(ia);
        }
        console.log(allInstalls)
        setInstalledApps(allInstalls);
      
    } catch( error ) {
      console.log(`Error fetching apps: ${error}`) ;
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        <HideAppBar 
          address={accountAddress}
          onConnect={connectWallet}
          onDisconnect={disconnectWallet}
        />
        <Container>

          <Typography variant="h1" style={{borderBottom: 'solid 4px white', marginBottom: 20}}>byoa app store</Typography>
          <Button onClick={handleConnection} color="inherit" variant="outlined">{accountAddress ? `${accountAddress.substring(0,10)}... Disconnect` : 'Connect Wallet'}</Button>
          <br/>
          <Typography style={{color: 'black', display: 'inline-block', marginTop: 10}} variant="caption" component="a" target="_blank" href={"https://etherscan.io/address/0x8f15c4ea6ce3fbfc5f7402c5766fc94202704161"}>byoa App Registry Verified Contract</Typography>

          <Box className={classes.tabBar}>
                <Paper className={classes.tabBarPaper}>
                    <Tabs
                        value={viewTab}
                        onChange={(e,n) => setViewTab(n)}
                        indicatorColor="secondary"
                        textColor="inherit"
                        
                    >
                        <Tab label="Available Apps" className={classes.tabBarTabs}/>
                        <Tab label="Your Apps" className={classes.tabBarTabs} />
                    </Tabs>
                </Paper>
            </Box>
            <Box>
              <Button startIcon={<CloudUploadIcon />} href="https://uniswap.byoa.org/" target="_blank" variant="outlined">Use your installed apps on the byoa Uniswap UI</Button>
            </Box>
            {viewTab === 0 && (
            <Box className={classes.apps}>
                <Box >
                    <Typography style={{display: 'inline-block'}} variant="h3">Available Apps</Typography>
                    <Button size="small" onClick={fetchAvailableApps} style={{display: 'inline-block'}}>[refresh]</Button>
                </Box>

                {apps.map( (app, i) => (
                    <AppCard 
                        key={`appcard-${app.id}`}
                        app={app}
                        accountAddress={accountAddress}
                        web3={web3}
                        abi={byoaAbi['abi']}
                        provider={provider}
                        onInstall={refreshMyApps}
                        installedApps={installedApps}
                    />
                ))}
                

                <Paper className={classes.appCard} elevation={3}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <img src={"https://uploads-ssl.webflow.com/61228f7fa04d4bded0f29865/6123e21cf49c9578b4bd3a8f_mallow-508.png"} width={'100%'} height={'auto'} style={{padding: 10}}/>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="h4">Mallows byoa - Genesis</Typography>
                      <Box className={classes.infoContainer}>
                        <Typography variant="body1">The Mallows project first introduced the concept of byoa by minting 10,000 unique Mallows.<br /><br />Mallows are generative art profile photos, and each mallow contains a toy byoa algorithm. <br /><br />Only 10,000 Mallows will ever exist. Learn more at <a style={{ marginRight: '0' }} href="https://mallows.xyz">mallows.xyz</a> or mint at <a style={{ marginRight: '0' }} href="https://mint.mallows.xyz">mint.mallows.xyz</a>.</Typography>
                      </Box>

                      <Box className={classes.infoContainer}>
                          <Typography variant="subtitle2">version:</Typography>
                          <Typography variant="body1">genesis</Typography>
                      </Box>

                      

                      <Box className={classes.infoContainer}>
                          <Typography variant="subtitle2">author:</Typography>
                          <Typography variant="body1">mallows team</Typography>
                      </Box>

                      <Box className={classes.infoContainer}>
                          <Button variant="outlined" href="https://mint.mallows.xyz">Mint Mallows</Button>
                          <Button variant="outlined" href="https://etherscan.io/address/0xf20eaeae0390803b1a7c8ab4c506870b81e1048e">View Contract</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
            </Box>
          )}

          {viewTab === 1 && (
            <Box className={classes.myApps}>
                <Box >
                    <Typography style={{display: 'inline-block'}} variant="h3">Your Apps</Typography>
                    <Button size="small" onClick={() => refreshMyApps(null)} style={{display: 'inline-block'}}>[refresh]</Button>
                </Box>

                {installedApps.map( (installedApp, i) => (
                    <InstalledAppCard
                        installedApp={installedApp}
                        key={`installedapp-${installedApp.id}`}
                    />
                ))}
            </Box>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
