import { useEffect, useState } from "react";
import { Box,   Button,   Grid,   makeStyles,Paper, Typography } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { ByoaApp } from "../types/byoaApp";
import { InstalledApp } from "../types/installedApp";
import { resolveIpfs } from "../utils/ipfs_resolver";
const useStyles = makeStyles({
    appCard: {
        background: 'rgba(255,255,255,0.5)',
        marginTop: 20,
        marginBottom: 20,
        padding: 20
    },
    infoContainer: {
        marginTop: 20,
        '& button': {
          marginRight: 25
        }
    },
    installationDetails: {
        paddingRight: 20,
        borderRight: 'solid 1px rgba(50,50,50,0.2)'
    },
    appContext: {
        paddingLeft: 20
    }
});

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function InstalledAppCard( props : {
    installedApp : InstalledApp
}) {
    const classes = useStyles();
    const [app, setApp] = useState<ByoaApp>(props.installedApp.app);
    const [installedApp, setInstalledApp] = useState<InstalledApp>(props.installedApp);
    const [meta, setMeta] = useState<any | null>(null);

    

    useEffect( () => {
        setApp(props.installedApp.app);
        setInstalledApp(props.installedApp);
    }, [props.installedApp.app]);

    useEffect( () => {
        fetchMeta();
    }, [app]);

    const fetchMeta = async () => {
        try {
            let zmeta = await fetch(resolveIpfs(app.tokenURI));
            let zjson = await zmeta.json();
            if (zjson !== null) {
                setMeta(zjson);
            }
        } catch(error) {
            console.log(error);
        }
    };
 
    return (
        <Paper className={classes.appCard} elevation={3}>
            <Grid container>
                <Grid item xs={12} sm={3} className={classes.installationDetails}>
                    <Typography variant="h4">byoa NFT Details</Typography>
                    
                    <Box>
                    {meta && (
                        <>
                        {/*@ts-ignore */}
                        <img src={resolveIpfs(meta['image'] || "")} width={'100%'} height={'auto'} style={{padding: 10}}/>
                        
                        </>
                    )}
                    </Box>

                    <Box className={classes.infoContainer}>
                        <Button variant="outlined" onClick={() => {}} disabled={true}>Uninstall</Button>
                        <br/>
                        <br/>
                        <Button variant="outlined" target="_blank" rel="noreferrer" href={resolveIpfs(app.tokenURI)}>Token URI Metadata</Button>
                    </Box>

                </Grid>
                <Grid item xs={12} sm={9} className={classes.appContext}>
                    <Typography variant="caption" style={{display: 'block', borderBottom: 'solid 1px rgba(20,20,20,0.2)', marginBottom: 30, paddingBottom: 10}}>App Details:</Typography>
                    <Typography variant="h4">{app.name}</Typography>
                    <Box className={classes.infoContainer}>
                        <Typography variant="subtitle2">description:</Typography>
                        <Typography variant="body1">{app.description}</Typography>
                    </Box>

                    <Box className={classes.infoContainer}>
                        <Typography variant="subtitle2">version:</Typography>
                        <Typography variant="body1">{app.version}</Typography>
                    </Box>

                    <Box className={classes.infoContainer}>
                        <Typography variant="subtitle2">author:</Typography>
                        <Typography variant="body1">mallows team</Typography>
                        <Typography variant="body1"><a target="_blank" rel="noreferrer" href={`https://etherscan.io/address/${app.owner}`}>{app.owner}</a></Typography>
                    </Box>
                </Grid>
            </Grid>

           
            
        </Paper>
    );
};