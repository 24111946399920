
// getGasData returns the current suggested gas price in gwei, and returns undefined if the gas API provider is unavialable.
export const getGasData = async () : Promise<Number | undefined> => {
    return new Promise<Number | undefined>( async (resolve, reject) => {
        try {
            let req = await fetch("https://data-api.defipulse.com/api/v1/egs/api/ethgasAPI.json?api-key=ccb859beaf88b6274485b72694eab01840554d4f469972e19952881d30ec");
            let data = await req.json();
        
            resolve(1000000000 * data['average']/10);
        } catch (error) {
            resolve(undefined);
            return;
        }
    })
};
