import React, { useEffect, useState } from "react";
import { Box, Button,  Grid,  makeStyles,Paper, Snackbar, Typography } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { ByoaApp } from "../types/byoaApp";
import { getGasData } from "../utils/gas";
import { resolveIpfs } from "../utils/ipfs_resolver";
import { InstalledApp } from "../types/installedApp";
const useStyles = makeStyles({
    appCard: {
        background: 'rgba(255,255,255,0.5)',
        marginTop: 20,
        marginBottom: 20,
        padding: 20
    },
    infoContainer: {
        marginTop: 20,
        '& button': {
          marginRight: 25
        }
    }
});

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AppCard( props : {
    app : ByoaApp,
    web3 : any,
    accountAddress : String | null,
    abi : any,
    provider : any,
    installedApps : InstalledApp[];
    onInstall : (txID : string) => void;
}) {
    const classes = useStyles();

    const [app, setApp] = useState<ByoaApp>(props.app);
    const [txID, setTxID] = useState<String | null>(null);
    const [txStatus, setTxStatus] = useState<String | null>(null);
    const [errMsg, setErrMsg] = useState<String | null>(null);
    const [meta, setMeta] = useState<any | null>(null);
    const [isInstalled, setIsInstalled] = useState<boolean>(false);

    useEffect( () => {
        setApp(props.app);
        
    }, [props.app]);

    useEffect( () => {
        fetchMeta();
        setIsInstalled(false);
        (props.installedApps || []).forEach( (ia : InstalledApp) => {
            if(ia.app.id === app.id) {
                setIsInstalled(true);
            }
        })
    }, [app, props.installedApps]);

    const fetchMeta = async () => {
        try {
            let zmeta = await fetch(resolveIpfs(app.tokenURI));
            let zjson = await zmeta.json();
            if (zjson !== null) {
                setMeta(zjson);
            }
        } catch(error) {
            console.log(error);
        }
    }

    const addApplication = async () => {
        if (props.accountAddress === null) {
            return;
        }
        try {
            setTxID(null);
            setErrMsg(null);
            setTxStatus(null);
            let contract = new props.web3.eth.Contract(props.abi, app.address);
            let callData = contract.methods.mint(app.id).encodeABI();

            const suggestedGasPrice = await getGasData();
            const mintParams = [
                {
                    from: props.accountAddress,
                    to: app.address,
                    gas: props.web3.utils.toHex(Math.min(160000*2,191695*10)), 
                    gasPrice: suggestedGasPrice !== undefined ? props.web3.utils.toHex(suggestedGasPrice) : undefined,
                    value: 0, 
                    data: callData
                },
            ];

            let res = await props.provider.request({
                method: 'eth_sendTransaction',
                params: mintParams,
            });
            setTxID(res);

            setTxStatus('pending');
            let receiptIV = setInterval( async () => {
                let data = await props.provider.request({
                    method: 'eth_getTransactionByHash',
                    params: [res],
                });
                if (data) {
                    if(data.blockHash !== null) {
                        clearInterval(receiptIV);
                        setTxStatus("processed");
                        if (props.onInstall) {
                            props.onInstall(res);
                        }
                    }
                }
            }, 2000);


        } catch (error) {
            console.log(error);
            setErrMsg(`Unable to process minting of byoa. App ID=${app.id}, Name='${app.name}'`);
        }
       
    };
    
    return (
        <Paper className={classes.appCard} elevation={3}>
            <Grid container>
                <Grid item xs={12} sm={3}>
                    {meta && (
                        <>
                        {/*@ts-ignore */}
                        <img src={resolveIpfs(meta['image'] || "")} width={'100%'} height={'auto'} style={{padding: 10}}/>
                        
                        </>
                    )}
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography variant="h4">{app.name}</Typography>
                    <Box className={classes.infoContainer}>
                        <Typography variant="body1">{app.description}</Typography>
                    </Box>

                    <Box className={classes.infoContainer}>
                        <Typography variant="subtitle2">version:</Typography>
                        <Typography variant="body1">{meta ? `${meta['version']}` : 'n/a'}</Typography>
                    </Box>

                    <Box className={classes.infoContainer}>
                        <Typography variant="subtitle2">author:</Typography>
                        <Typography variant="body1">mallows team</Typography>
                        <Typography variant="body1"><a target="_blank" rel="noreferrer" href={`https://etherscan.io/address/${app.owner}`}>{app.owner}</a></Typography>
                    </Box>

                    <Box className={classes.infoContainer}>
                        <Button variant="outlined" onClick={addApplication} disabled={props.accountAddress === null || txID !== null || isInstalled}>{isInstalled ? 'Installed' : 'Install to wallet'}</Button>
                    </Box>
                    <Box>
                        {txID !== null && (
                            <>
                                <Typography variant="subtitle2">transaction:</Typography>
                                <Typography variant="body1"><a target="_blank" href={`https://etherscan.io/tx/${txID}`}>{txID}</a></Typography>
                            </>
                        )}
                        {txStatus !== null && (
                            <Typography variant="body1">tx status: {txStatus}</Typography>
                        )}
                    </Box>
                    <Snackbar open={errMsg !== null} autoHideDuration={5000} onClose={() => setErrMsg(null)}>
                        <Alert severity="error">{errMsg}</Alert>
                    </Snackbar>
                </Grid>
            </Grid>
        </Paper>
    );
};